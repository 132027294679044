import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MainLayout from 'src/layout/MainLayout';
import {
  SectionHead,
  HalvedContent,
  ContentBox,
  Dialog,
  Button,
  List,
  Grid,
  NonStretchedImage,
  Paragraphs
} from 'src/components';

import content from 'src/content/unsere-loesungen/absicherung-fuer-mitarbeiter/betrieblicher-einkommensschutz/eks-berufsunfaehigkeit';
import subpageNavigations from 'src/data/subpage-navigations';

const EKSBerufsunfaehigkeitPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline
    }}
    subpageNavItems={subpageNavigations.betrieblicherEinkommensschutz}
    activeNavItem={1}
    useParentPathname
  >
    <section>
      <SectionHead headline={content.kapitalHeader.headline} centered>
        <p>{content.kapitalHeader.content}</p>
      </SectionHead>
      <HalvedContent align="center">
        <BackgroundImage
          fluid={data.womanSittingAtOfficeDesk.childImageSharp.fluid}
        />
        <ContentBox
          background={content.betroffenCard.background}
          headline={content.betroffenCard.headline}
          wider
        >
          <Paragraphs items={content.betroffenCard.paragraphs} />
          <Dialog
            buttonText={content.betroffenCard.dialog.text}
            buttonVariant={content.betroffenCard.dialog.variant}
            title={content.betroffenCard.dialog.title}
          >
            <Paragraphs items={content.betroffenCard.dialog.paragraphs} />
          </Dialog>
        </ContentBox>
      </HalvedContent>
    </section>
    <section>
      <Grid centerItems>
        <div className="children-margin">
          <h3>{content.ursachenSection.leftTopSection.headline}</h3>
          <Paragraphs
            items={content.ursachenSection.leftTopSection.paragraphs}
          />
          <h3>{content.ursachenSection.leftBottomSection.headline}</h3>
          <Paragraphs
            items={content.ursachenSection.leftBottomSection.paragraphs}
          />
        </div>
        <NonStretchedImage fluid={data.ursachenBU.childImageSharp.fluid} />
      </Grid>
    </section>
    <section className="no-children-margin">
      <HalvedContent align="center">
        <ContentBox
          headline={content.highlightsSection.leftCard.headline}
          background={content.highlightsSection.leftCard.background}
          wider
        >
          <Paragraphs items={content.highlightsSection.leftCard.paragraphs} />
        </ContentBox>
        <ContentBox
          headline={content.highlightsSection.rightCard.headline}
          background={content.highlightsSection.rightCard.background}
        >
          <List items={content.highlightsSection.rightCard.advantages} />
        </ContentBox>
      </HalvedContent>
    </section>
    <section>
      <ContentBox
        headline={content.vorteileSection.headline}
        background={content.vorteileSection.background}
        single
      >
        <List items={content.vorteileSection.advantages} columns={2} />
      </ContentBox>
    </section>

    <section className="no-children-margin">
      <HalvedContent align="center">
        <ContentBox
          headline={content.zusatzbaustein.leftCard.headline}
          background={content.zusatzbaustein.leftCard.background}
          single
        >
          <Paragraphs items={content.zusatzbaustein.leftCard.paragraphs} />
        </ContentBox>
        <ContentBox
          headline={content.zusatzbaustein.rightCard.headline}
          background={content.zusatzbaustein.rightCard.background}
          wider
        >
          <List items={content.zusatzbaustein.rightCard.advantages} />
        </ContentBox>
      </HalvedContent>
    </section>
  </MainLayout>
);

export default EKSBerufsunfaehigkeitPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "young-stylish-buisiness-man-wide.jpg" }
    ) {
      ...image
    }
    womanSittingAtOfficeDesk: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-sitting-at-office-desk.jpg" }
    ) {
      ...image
    }
    ursachenBU: file(
      sourceInstanceName: { eq: "illustrations" }
      relativePath: { eq: "bu-ursachen-202104..png" }
    ) {
      ...image
    }
    manLeaningOnGlass: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-leaning-on-glass.jpg" }
    ) {
      ...image
    }
  }
`;
