const content = {
  title: 'EKS - Berufsunfähigkeit',
  headvisualHeadline: 'Betrieblicher Einkommensschutz (EKS)',
  kapitalHeader: {
    headline: 'Sicher ihr größtes Kapital - Ihre Mitarbeiter',
    content:
      'Mit der betrieblichen Berufsunfähigkeitsversicherung bieten Sie Ihren Mitarbeitern Schutz für den Fall einer Berufsunfähigkeit – und das besonders günstig im Rahmen einer betrieblichen Altersversorgung (bAV). Das zahlt sich für Sie in jeder Hinsicht aus: Sie erhöhen durch diese besondere Wertschätzung die Mitarbeiterzufriedenheit und machen sich als Arbeitgeber für neue Bewerber interessant.'
  },
  betroffenCard: {
    headline: 'Jeder 4. ist betroffen',
    background: 'dark-blue',
    paragraphs: [
      'Tatsächlich scheidet jeder 4. Arbeitnehmer vorzeitig aus dem Berufsleben aus. Ein Unfall, eine schwere Krankheit – und schon fällt mit einem Schlag das Einkommen Ihres Mitarbeiters weg.',
      'Wenn das regelmäßige Gehalt wegfällt, brauchen Ihre Mitarbeiter trotzdem ein Einkommen.'
    ],
    dialog: {
      text: 'Mehr lesen',
      variant: 'ghost',
      title: 'Jeder 4. ist betroffen',
      paragraphs: [
        'Denn der Staat zahlt nur unter bestimmten Voraussetzungen eine Erwerbsminderungsrente - im Schnitt gerade mal ca. 830 EUR. Das reicht bei Weitem nicht aus, um Fixkosten zu decken oder gar eine Familie zu versorgen.',
        'Deshalb sollte dieses Risiko abgesichert werden – weil die Arbeitskraft das größte Kapital Ihrer Mitarbeiter ist – und auch Ihres.'
      ]
    }
  },
  ursachenSection: {
    leftTopSection: {
      headline: 'Häufig unterschätzt.',
      paragraphs: [
        'Meistens sind es Krankheiten, die zu einer BU führen. Das kann jedem passieren, in jedem Alter. Besonders die jungen Arbeitnehmer haben dann meist noch kein finanzielles Polster, mit dem sie eine dauernde oder zeitlich begrenzte Berufsunfähigkeit überbrücken könnten.',
        'Trotzdem sorgen immer noch zu wenige Arbeitnehmer für den Fall der Fälle vor. Mit Ihrer Hilfe jedoch wird dieser wichtige Schutz bezahlbar.'
      ]
    },
    leftBottomSection: {
      headline: 'BU-Versicherung: Sie machen es möglich!',
      paragraphs: [
        'Mit der betrieblichen Berufsunfähigkeitsversicherung sichern Sie vom Auszubildenden über den Angestellten bis hin zu Führungskräften alle Personenkreise im alter zwischen 14 und 62 Jahren bis zum gesetzlichen Renteneintrittsalter ab. Den Beitrag für den BU-Schutz können Sie komplett übernehmen oder den Beitrag Ihrer Mitarbeiter bezuschussen. In beiden Fällen sind Ihre Ausgaben komplett als Betriebsausgabe absetzbar.'
      ]
    }
  },
  highlightsSection: {
    leftCard: {
      headline: 'NÜRNBERGER Selbstständige Berufsunfähigkeitsversicherung.',
      paragraphs: [
        'Die selbstständige Berufsunfähigkeitsversicherung bietet für Arbeitnehmer Schutz in Form einer monatlichen Rente bei eingetretener bedingungsgemäßer Berufsunfähigkeit. Grundbaustein ist die Komfort Berufsunfähigkeitsversicherung mit Verzicht auf die abstrakte Verweisung.',
        'Grundbaustein: Komfort-BU - hochwertiger BU-Schutz mit Top-Bedingungen + Inflations-Schutz - garantierte Rentensteigerung im Leistungsfall (wählbar zwischen 0,5 und 3 %)'
      ],
      background: 'light-pink'
    },
    rightCard: {
      headline: 'Highlights der betrieblichen Berufsunfähigkeitsversicherung',
      advantages: [
        'Vorschussleistungen bei Erkrankung an einem bösartigen Tumor (bis zum Abschluss der Leistungsprüfung) maximal 15 Monate',
        'Wiederinkraftsetzung innerhalb von 12 Monaten ohne Gesundheitsprüfung',
        'Zinslose Beitragsstundung z.B. bei längerer Arbeitsunfähigkeit, Elternzeit und Arbeitslosigkeit etc.',
        'Möglichkeit, die vereinbarte Rente bei bestimmten Ereignissen (z.B. Heirat) ohne erneute Gesundheitsfragen zu erhöhen'
      ],
      background: 'light-blue'
    }
  },
  vorteileSection: {
    headline: 'Ihr Vorteile als Arbeitgeber',
    background: 'light-blue',
    advantages: [
      'Sie steigern Ihre Attraktivität als Arbeitgeber – und haben bessere Chancen bei der Suche nach Fachkräften',
      'Die Ausgaben können Sie komplett als Betriebsausgaben absetzen',
      'Sie erhöhen die Loyalität Ihrer Mitarbeiter mit Ihrem Unternehmen und senken die Fluktuation',
      'Die NÜRNBERGER fördert und bezuschusst Reha-Maßnahmen, um eine schnellere Genesung und damit eine zeitnahe Rückkehr ins Berufsleben zu ermöglichen',
      'Sie zeigen soziale Verantwortung & Wertschätzung gegenüber Ihren Arbeitnehmern',
      'Mit unseren bAV-Portalen verwalten Sie die Verträge online – ohne großen Aufwand',
      'Sie sorgen dafür, dass Ihre Mitarbeiter leichter einen BU-Schutz bekommen'
    ]
  },

  zusatzbaustein: {
    leftCard: {
      headline:
        'Noch sicherer mit dem neuen Zusatzbaustein: Beitragsbefreiung bei Arbeitsunfähigkeit.',
      paragraphs: [
        'Mit unserem neuen Zusatzbaustein schaffen Sie noch mehr Sicherheit für Ihre Arbeitnehmer. Denn längere Krankheit kann dazu führen, dass die Lohnfortzahlung wegfällt. Ab diesem Zeitpunkt wird in der Regel die Zahlung in die bAV und somit auch in die betriebliche Berufsunfähigkeitsversicherung eingestellt. Versicherungsschutz besteht dann lediglich noch in Höhe der beitragsfreien Versicherungssumme.'
      ],
      background: 'dark-blue'
    },
    rightCard: {
      headline:
        'Unsere passende Lösung: Der Zusatzbaustein "Beitragsbefreiung bei Arbeitsunfähigkeit"',
      advantages: [
        'Beitragsbefreiung bei Arbeitsunfähigkeit nach Wegfall der Entgeltfortzahlung für max. 6 Kalendermonate',
        'Beitragszahlung wird durch NÜRNBERGER übernommen',
        'Aufrechterhaltung des vollen und lückenlosen BU-Schutzes Ihrer Arbeitnehmer',
        'Aussetzungszeitraum endet spätestens nach Ablauf von 6 Kalendermonaten',
        'Einfache Abwicklung bei Ende der Lohnfortzahlung wegen AU für Sie',
        'Den Baustein gibt es zum ganz kleinen Preis'
      ],
      background: 'light-blue'
    }
  }
};

export default content;
